<template lang="pug">
div#lts-top.small.bold
  div.score.flex.mod.small(v-if="questions[current]")
    div.grow
    div.flex
      h4 本轮测试
      div.icon-small.margin._0_5(v-for="i in scores" :class="i?'right':'wrong'")
  div.blue_red_line
  div.mod.exam(v-if="questions[current]")
    h5.margin {{questions[current].isMultiple?'多':'单'}}选题
    div.question.margin {{questions[current].eqname}}
    ul.answers
      li.flex.start.margin(v-for="(n,i) in questions[current].answer_list" @click="_check(i)")
        div.id {{['A','B','C','D'][i]}}
        div.grow {{n.answer_con}}
        div.icon-noraml.right(:class='{uncheck: !as[i]}')
  div.result.mod(v-if="questions[current]")
    div.ing.right.txt.red(v-if="state == 0")
      div: span(@click="_commit") 提交答案
    div.finish(v-else-if="state == 1")
      div.flex.txt.red
        div.grow.txt(:class="currentScore? 'blue': 'red'") {{currentScore? '正确': '错误'}}
        div.a(@click="_next") 下一题
      div(v-if="!currentScore")
        | 本题正确答案：
        span(v-for="a,i in questions[current].as") {{a / 1 ? ['A','B','C','D'][i] : ''}}
    div.ing.right.txt.blue(v-else)
      div: span.a 答题完毕
</template>

<script>
import exam from '@/mixins/exam'
export default {
  mixins: [exam],
  mounted(){
    this.questionFactory('ltsTop')
  }
}
</script>